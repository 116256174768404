import { User } from '@supabase/supabase-js';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo, useState } from 'react';
import { useAliveController } from 'react-activation';
import { useLocation } from 'react-router-dom';

import { ButtonWithTooltips } from 'ui';
import { useMedia } from 'react-use';
import { Aside, CommonPage, Footer, Header, WorkerTerminalProvider } from './components';
import {
    ASIDE_CLOSE_WIDTH,
    ASIDE_CLOSE_WIDTH_MOBILE,
    ASIDE_OPEN_WIDTH,
    ID_REGX,
    USER_ID_REGX
} from './components/constants';

import './DefaultLayout.scss';

const { Content } = Layout;

// Import the image
// import christmasBorderTop from 'assets/images/christmas-border.png';

// import christmasBorderBottom from 'assets/images/christmas-border-full.png';
interface DefaulLayoutProps extends React.PropsWithChildren {
    user: User | null;
    setUser: (user: User | null) => void;
    numNotifications: number;
}

export const DefaultLayout = observer<DefaulLayoutProps>(
    ({ children, user, setUser, numNotifications }) => {
        const isMiddleTablet = useMedia('(max-width: 720px)');
        const { pathname, state: locationState } = useLocation();
        const { dropScope } = useAliveController();

        const { pathnameLastElem, pathnameLastElemForDetail, isDetailPage } = useMemo(() => {
            const pathnameLastElem = pathname.split('/').at(-1)?.trim() as string;
            const pathnameLastElemForDetail = pathname.split('/').at(-2)?.trim() as string;

            const isDetailPage =
                ID_REGX.test(pathnameLastElem) ||
                USER_ID_REGX.test(pathnameLastElem) ||
                pathnameLastElem === ':' ||
                pathnameLastElem === 'new';

            return {
                pathnameLastElem,
                pathnameLastElemForDetail,
                isDetailPage
            };
        }, [pathname]);

        const isBookingSlotsPage = pathnameLastElem === 'resource-planner';
        const isNotesPage = pathname.includes('notes');
        const isChangelogPage = pathname.includes('changelog');
        const isReportPage = pathnameLastElem === 'report';

        const storedOpenState = sessionStorage.getItem('menuOpen');

        const [open, setOpen] = useState<boolean>(
            storedOpenState ? JSON.parse(storedOpenState) : false
        );

        const handleDropScope = useCallback(() => {
            dropScope(
                !isDetailPage
                    ? isBookingSlotsPage
                        ? `resourcePlanner_${locationState?.cacheKey || pathname}`
                        : isNotesPage
                        ? `notesPage`
                        : isChangelogPage
                        ? `changelogPage`
                        : isReportPage
                        ? `reportPage_${locationState?.cacheKey ?? pathname}`
                        : `tablePage_${locationState?.cacheKey || pathname}`
                    : `detailPage_${locationState?.cacheKey || pathname}`
            );
        }, [
            dropScope,
            isDetailPage,
            isBookingSlotsPage,
            locationState?.cacheKey,
            pathname,
            isNotesPage,
            isChangelogPage,
            isReportPage
        ]);

        const handleAsideToggle = useCallback(() => {
            setOpen((prev) => {
                sessionStorage.setItem('menuOpen', JSON.stringify(!prev));
                return !prev;
            });
        }, []);

        const handleCloseAside = useCallback(() => {
            setOpen(false);
            sessionStorage.setItem('menuOpen', JSON.stringify(false));
        }, []);

        const calcMarginLeftByScreenSize = () => {
            if (isMiddleTablet) return ASIDE_CLOSE_WIDTH_MOBILE;
            return open ? ASIDE_OPEN_WIDTH : ASIDE_CLOSE_WIDTH;
        };

        return (
            // <WorkerTerminalProvider onlyChildren={children}>
            <Layout style={{ minHeight: '100vh' }}>
                <Aside isOpenStatus={open} onClose={handleCloseAside} />
                <Layout style={{ position: 'relative' }}>
                    <Header
                        user={user}
                        setUser={(user) => {
                            setOpen(false);
                            setUser(user);
                        }}
                        numNotifications={numNotifications}
                        style={{
                            marginLeft: calcMarginLeftByScreenSize(),
                            minWidth: `calc(100vw - ${calcMarginLeftByScreenSize()}px)`
                            // backgroundColor: 'red',
                            // backgroundImage: `url(${christmasBorderTop})`,
                            // backgroundSize: 'contain',
                            // backgroundPosition: 'center',
                            // backgroundColor: '#EDE7DD'

                            // backgroundRepeat: 'no-repeat'
                        }}
                        // breadcrumbs={<Breadcrumbs />}
                        toggleMenuButton={
                            <ButtonWithTooltips
                                id="menu_mode"
                                className="btn-menu"
                                onClick={handleAsideToggle}
                                icon={open ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
                                // icon={open ? <LayoutFilled /> : <LayoutOutlined />}
                                type="text"
                            />
                        }
                    />
                    <Content
                        style={{
                            marginLeft: calcMarginLeftByScreenSize(),
                            overflow: 'auto',
                            maxHeight: 'calc(100vh - 40px - 50px)'
                        }}
                        className="default-layout__content"
                    >
                        <CommonPage
                            dropScope={handleDropScope}
                            isDetailPage={isDetailPage}
                            pathnameLastElem={pathnameLastElem}
                            pathnameLastElemForDetail={pathnameLastElemForDetail}
                        >
                            {children}
                        </CommonPage>
                    </Content>
                    <Footer
                        style={{
                            marginLeft: calcMarginLeftByScreenSize(),
                            width: `calc(100vw - ${calcMarginLeftByScreenSize()}px)`
                        }}
                    />
                </Layout>
            </Layout>
            // </WorkerTerminalProvider>
        );
    }
);
